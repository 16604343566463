import React from "react";
import { Button, Grid, MenuItem, Select, TextField} from "@material-ui/core";
import {
 sortby,
 subject,
 oneActiveIcon,
  threeActiveIcon,
  twotActiveIcon,
} from "../assets";
import CheckBoxComponent from "./CheckboxComponent";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import  "../../assets/index.scss"
import { S } from "../ClassboxesController";
interface FilterComponentProps {
  state:S;
  handletab0Checkbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handletab1Checkbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCompareChange: (compareType: "is" | "between" | "morethan" | "lessthan") => void;
  handleChangeV1: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeV2: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSortChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleButtonClick:(subject:string)=>void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  state,
  handletab0Checkbox,
  handletab1Checkbox,
  handleCompareChange,
  handleChangeV1,
  handleChangeV2,
  handleSortChange,
  handleButtonClick
}) => {
   
  const { activeSubject, sortOrder, tab0Checkboxes, tab1Checkboxes, requestType } = state;
  const areFiltersActive = () => {
    return (
      activeSubject !== "" || 
      sortOrder !== "" || 
      Object.values(tab0Checkboxes).some((value) => value) || 
      Object.values(tab1Checkboxes).some((value) => value) || 
      requestType.compare !== ""
    );
  };
 
  const subjects = [
    { name: "3D Modelling", icon: "🖥️" },
      { name: "Computer Science", icon: "🤖" },
      { name: "ELA", icon: "📖" },
      { name: "Electronics", icon: "🔋" },
      { name: "Game Design", icon: "🎮" },
      { name: "Science", icon: "🔬" },
  ];
  return (
    <Grid item container sm={5} className="right-container">
          <div className="filterDrawer">
            <div className="filterName">
              <div className="filterheading">Filter & Sort</div>
              {areFiltersActive() && (
             <div className="clearButton">
              Reset
            </div>
          )}
            </div>
            <div className="sortByDiv">
              <div className="sortBy">
                <img src={sortby}/>
                <div className="sortByName">Sort by</div>
              </div>
              <div className="sort-dropdown-container">
        <Select
          value={state.sortOrder}
          onChange={handleSortChange}
          IconComponent={KeyboardArrowDownIcon}
          disableUnderline
          className="sort-dropdown"
          placeholder="Sort By Price"
        >
          <MenuItem value="highToLow">Price (high to low)</MenuItem>
          <MenuItem value="lowToHigh">Price (low to high)</MenuItem>
        </Select>
      </div>
            </div>
            <div className="RequestType">
            <div className="sortByDiv">
              <div className="sortBy" style={{marginBottom:"16px"}}>
                <img src={oneActiveIcon}/>
                <div className="sortByName">Request Types</div>
              </div>
              <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab0Checkbox}
                checked={state.tab0Checkboxes.Purchase}
                label="Purchase"
                name="Purchase"
              />
            </Grid>
            <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab0Checkbox}
                checked={state.tab0Checkboxes.Checkout}
                label="Checkout"
                name="Checkout"
              />
            </Grid>
            </div>
          </div>
          <div className="CreditCost">
            <div className="sortByDiv">
              <div className="sortBy">
                <img src={twotActiveIcon}/>
                <div className="sortByName">Credit Cost</div>
              </div>
              <Grid className="tab1-content" container>
              <Grid item container className="compare" sm={12} spacing={2}>
              <div className="compareButtons">
                  <div
                    id="checkout-btn3"
                    data-test-id="checkoutBtn1"
                    onClick={() => handleCompareChange("is")}
                    className={
                      state.requestType.compare === "is"
                        ? "activebtnClassboxFilter"
                        : "nonactivebtnClassboxFilter"
                    }
                  >
                    <span>Is</span>
                  </div>
              
                  <div
                    id="compare0"
                    onClick={() => handleCompareChange("between")}
                    data-test-id="checkoutBtn2"
                    className={
                      state.requestType.compare === "between"
                        ? "activebtnClassboxFilter"
                        : "nonactivebtnClassboxFilter"
                    }
                  >
                    <span>Is between</span>
                  </div>
               
                  <div
                    id="compare1"
                    onClick={() => handleCompareChange("morethan")}
                    data-test-id="checkoutBtn3"
                    className={
                      state.requestType.compare === "morethan"
                        ? "activebtnClassboxFilter"
                        : "nonactivebtnClassboxFilter"
                    }
                  >
                    <span>More than</span>
                  </div>
                
                  <div
                    id="compare2"
                    data-test-id="checkoutBtn4"
                    onClick={() => handleCompareChange("lessthan")}
                    className={
                      state.requestType.compare === "lessthan"
                        ? "activebtnClassboxFilter"
                        : "nonactivebtnClassboxFilter"
                    }
                  >
                    <span>Less than</span>
                  </div>
              
              </div>
              </Grid>
            
            {state.requestType.compare !== "" && (
              <Grid item container sm={12} className="fields">
                <Grid
                  item
                  sm={state.requestType.compare === "between" ? 6 : 12}
                >
                  <TextField
                   className={state.requestType.compare === "between" ? "input-field1" :"input-field"}
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Value"
                    onChange={handleChangeV1}
                    value={state.requestType.value1}
                    fullWidth
                  />
                </Grid>
                {state.requestType.compare === "between" && (
                  <Grid item sm={6}>
                    <TextField
                      className="input-field2"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Value"
                      onChange={handleChangeV2}
                      value={state.requestType.value2}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
            </div>
          </div>
          <div className="Subjects">
            <div className="sortByDiv">
              <div className="sortBy">
                <img src={subject}/>
                <div className="sortByName">Related Subjects</div>
              </div>
              <div className="subjects-container">
        <Grid container spacing={2}>
          {subjects.map((subject) => (
              <Button
                className={`subject-button ${
                  activeSubject === subject.name ? "active" : ""
                }`}
                data-test-id="subjectBtn"
                onClick={() =>handleButtonClick(subject.name)}
              >
                <span className="icon">{subject.icon}</span>
                {subject.name}
              </Button>
  
          ))}
        </Grid>
      </div>
          </div>
          </div>

         

          <div className="ClassBoxType">
            <div className="sortByDiv">
              <div className="classboxTypes">
                <img src={threeActiveIcon}/>
                <div className="sortByName">Classbox Type</div>
              </div>
              <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab1Checkbox}
                checked={state.tab1Checkboxes.makerspaceEquipment}
                label="Makerspace Equipment"
                name="makerspaceEquipment"
              />
            </Grid>
            <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab1Checkbox}
                checked={state.tab1Checkboxes.STEMTechnology}
                label="STEM Technology"
                name="STEMTechnology"
              />
            </Grid>
            <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab1Checkbox}
                checked={state.tab1Checkboxes.reusableMaterials}
                label="Reusable Materials"
                name="reusableMaterials"
              />
            </Grid>
            <Grid item sm={12}>
              <CheckBoxComponent
                handleChange={handletab1Checkbox}
                checked={state.tab1Checkboxes.consumableMaterials}
                label="Consumable Materials"
                name="consumableMaterials"
              />
            </Grid>
            </div>
          </div>
          </div>
    </Grid>
  );
};

export default FilterComponent;
