// Customizable Area Start
import React from "react";
import { Grid, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ClassboxesController, { Props } from "./ClassboxesController";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import "../assets/index.scss";
import OrderModal from "./components/OrderModal";
import ClassBoxesCards from "./components/ClassBoxesCards";
import ClassBoxDetails from "./components/ClassBoxDetails";
import { setCurrentClassbox } from "../../../components/src/NativeWebRouteWrapper/classboxesSlice";
import NotificationCard from "./components/NotificationCard";
import FilterComponent from "./components/FilterComponent";

class Classboxes extends ClassboxesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container spacing={5} className="classboxes-container1" style={Object.keys(this.props?.classBoxesReduxData?.currentClassboxData || {})
      .length > 0 ? {backgroundColor: "white"} : {}}>
        <Grid item container sm={7} className="left-container padding-card-cs">
          <Grid item sm={12} className="header">
            <span className="title">📦 Supplies</span>
          </Grid>
          <NotificationCard
            state={this.state}
            navigation={this.props.navigation}
            handleCheckOut={this.handleCheckOut}
          />
          <div className="relative-grid-class">
            <div className="suppliesLine">Viewing supplies and equipment available for <span className="suppliesLineSpan">checkout</span> or <span className="suppliesLineSpan">purchase.</span></div>
            <ClassBoxesCards
              state={this.state}
              setClassBoxDetails={this.setClassBoxDetails}
              itemId={this.props.classBoxesReduxData.currentClassboxData?.id}
            />
          </div>
        </Grid> 
        {Object.keys(this.props?.classBoxesReduxData?.currentClassboxData || {})
          .length > 0 && (
          <ClassBoxDetails
            state={this.state}
            propsdata={this.props?.classBoxesReduxData}
            navigation={this.props.navigation}
            setClassBoxDetails={this.setClassBoxDrawerCloseDetails}
            handleCheckOut={this.handleCheckOut}
          />
        )}
         <FilterComponent
                state={this.state}
                handletab1Checkbox={this.handletab1Checkbox}
                handletab0Checkbox={this.handletab0Checkbox}
                handleChangeV1={this.handleChangeV1}
                handleChangeV2={this.handleChangeV2}
                handleCompareChange={this.handleCompareChange}
                handleSortChange={this.handleSortChange}
                handleButtonClick={this.handleButtonClick}
              />
        {this.state.selectedCheckoutCard?.classbox_parts?.data && (
          <OrderModal
            handleClose={this.handleOrderModal}
            handleModalTabChange={this.handleModalTabChange}
            state={this.state}
            handleReturnClassBox={this.handleReturnClassBox}
            handleKeepClassBox={this.handleKeepClassBox}
            handleExtendClassBox={this.handleExtendClassBox}
            requestCreditsForTraining={this.requestCreditsForTraining}
          />
        )}
        <LoaderComponent open={this.state.loading} />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.showSnackbar}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
      </Grid>
      // Customizable Area End
    );
  }
}

export const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentClassbox: (data: any) => {
      dispatch(setCurrentClassbox(data));
    },
  };
};
export const mapStateToProps = (state: any) => {
  return {
    classBoxesReduxData: state.classboxesSlice,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Classboxes);
// Customizable Area End
