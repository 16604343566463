export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const backIcon = require("../assets/back-icon.svg");
export const calIcon = require("../assets/cal-icon.svg");
export const vectorIcon = require("../assets/vector-icon.svg");
export const iIcon = require("../assets/i-icon.svg");

export const filterIcon = require("../assets/filterIcon.svg");
export const listBack = require("../assets/backimage.png");
export const warningIcon = require("../assets/warningIcon.svg");
export const chatIcon = require("../assets/chat.svg");
export const oneIcon = require("../assets/one.svg");
export const twotIcon = require("../assets/two.svg");
export const threeIcon = require("../assets/three.svg");
export const duration = require("../assets/duration.svg");
export const calender = require("../assets/calender.svg");
export const plus = require("../assets/plus.svg");
export const minus = require("../assets/minus.svg");
export const truck = require("../assets/truck.svg");




export const crossIcon = require("../assets/cross.svg");
export const backCardImage = require("../assets/backCard1.png");
export const rightIcon = require("../assets/right.svg");
export const rightIconOrange = require("../assets/rightOrangs.svg");
export const fileBlueIcon = require("../assets/fileBlue.svg");
export const oneActiveIcon = require("../assets/oneActive.svg");
export const twotActiveIcon = require("../assets/twoActive.svg");
export const threeActiveIcon = require("../assets/threeActive.svg");
export const orangeWarn = require("../assets/orangeWarn.svg");
export const appLogo = require("../assets/applogo.svg");
export const cart = require("../assets/cart.svg");
export const rightBtn = require("../../catalogue/assets/rightArrow.svg");
export const coin = require("../assets/coins.svg");
export const approved = require("../assets/approved.svg");
export const confirmation = require("../../catalogue/assets/confirmation.svg");
export const hand = require("../assets/hand.svg");
export const thunder = require("../assets/thunder.svg");
export const error = require("../assets/error.svg");
export const errorone = require("../assets/errorone.svg");
export const warningIconOrange = require("../assets/warningIconOrange.svg");
export const FrameImage = require("../assets/Frame.png");
export const errorMinus = require("../assets/errorMinus.svg");
export const errorplus = require("../assets/errorplus.svg");
export const cal = require("../assets/cal.svg");
export const rightIconWhite = require("../assets/onecircle.svg");
export const SelectedClassboxBack = require("../assets/selected_classbox.svg");
export const sortby = require("../assets/sortby.svg");
export const subject = require("../assets/subject.svg");
export const documentdownload = require("../assets/documentdownload.png");



