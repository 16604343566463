// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getAuthToken, handleTokenError } from "../../../components/src/NativeWebRouteWrapper/Utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classBoxesReduxData: any;
  setCurrentClassbox: any;

  // Customizable Area End
}
type Color = "success" | "info" | "warning" | "error" | undefined;
export interface S {
  // Customizable Area Start
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  classBoxes: any[];
  tab: string;
  activeSubject: string;
  requestType: {
    type: "checkout" | "purchase" | "";
    compare: "is" | "between" | "morethan" | "lessthan" | "";
    value1: any;
    value2: any;
  };
  tab0Checkboxes: {
    Purchase: boolean;
    Checkout: boolean;
  };
  tab1Checkboxes: {
    makerspaceEquipment: boolean;
    STEMTechnology: boolean;
    reusableMaterials: boolean;
    consumableMaterials: boolean;
  };
  openFilter: boolean;
  filterSelected: boolean;
  tab0Show: any[];
  tab1show: any[];
  tab2show: string;
  modalTab: string;
  orderModalOpen: boolean;
  page: number;
  perPage: number;
  pagination: any;
  getClasssboxFiltersURL: string;
  alignment: any;
  quantityCheckOut: number;
  quantityPurchase: number;
  cardDetails: boolean;
  purchaseDetails: any;
  purchasedClassboxes: [];
  selectedCheckoutCard: any;
  errorMessage: boolean;
  totalCost: number;
  newDate: string;
  sortOrder:string;
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class ClassboxesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getClassBoxesApiId: any;
  getPurchaseDetailsId: any;
  returnClassBoxApiCallId: any;
  keepClassBoxApiCallId: any;
  extendClassBoxApiCallId: any;
  requestCreditsApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      classBoxes: [],
      tab: "tab0",
      requestType: {
        type: "",
        compare: "",
        value1: "",
        value2: "",
      },
      tab0Checkboxes: {
        Purchase: false,
        Checkout: false,
      },
      tab1Checkboxes: {
        makerspaceEquipment: false,
        STEMTechnology: false,
        reusableMaterials: false,
        consumableMaterials: false,
      },
      openFilter: false,
      filterSelected: false,
      activeSubject: "",
      alignment: "0",
      tab0Show: [],
      tab1show: [],
      tab2show: "",
      modalTab: "keep",
      orderModalOpen: false,
      page: 1,
      perPage: 8,
      pagination: {},
      getClasssboxFiltersURL: "",
      quantityCheckOut: 1,
      quantityPurchase: 1,
      cardDetails: false,
      purchaseDetails: {},
      purchasedClassboxes: [],
      selectedCheckoutCard: {start_date :""},
      errorMessage: false,
      totalCost: 0,
      newDate: "date",
      sortOrder: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getClassboxesData();

    // Customizable Area End
  }
  async componentWillUnmount() {
    this.props.setCurrentClassbox(null);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    if (
      prevState.tab0Checkboxes !== this.state.tab0Checkboxes ||
      prevState.tab1Checkboxes !== this.state.tab1Checkboxes ||
      prevState.requestType !== this.state.requestType
    ) {
      let x: any[] = [];
      let y: any[] = [];
      this.state.tab0Checkboxes.Checkout
        ? x.push("Checkout")
        : x.filter((x) => x === "Checkout");
      this.state.tab0Checkboxes.Purchase
        ? x.push("Purchase")
        : x.filter((x) => x === "Purchase");

      this.state.tab1Checkboxes.STEMTechnology
        ? y.push("STEM Technology")
        : y.filter((x) => x === "STEM Technology");
      this.state.tab1Checkboxes.consumableMaterials
        ? y.push("Consumable Materials")
        : y.filter((x) => x === "Consumable Materials");
      this.state.tab1Checkboxes.makerspaceEquipment
        ? y.push("Makerspace Equipment")
        : y.filter((x) => x === "Makerspace Equipment");
      this.state.tab1Checkboxes.reusableMaterials
        ? y.push("Reusable Materials")
        : y.filter((x) => x === "Reusable Materials");

      if (
        this.state.requestType.compare === "between" &&
        this.state.requestType.type !== "" &&
        this.state.requestType.value1 !== "" &&
        this.state.requestType.value2 !== ""
      ) {
        this.setState({
          tab2show: `Between ${this.state.requestType.value1} and ${this.state.requestType.value2}`,
        });
      } else if (
        this.state.requestType.compare !== "between" &&
        this.state.requestType.type !== "" &&
        this.state.requestType.value1 !== ""
      ) {
        switch (this.state.requestType.compare) {
          case "is":
            this.setState({
              tab2show: `${this.state.requestType.value1}`,
            });
            break;
          case "lessthan":
            this.setState({
              tab2show: `Less than ${this.state.requestType.value1}`,
            });
            break;
          case "morethan":
            this.setState({
              tab2show: `More than ${this.state.requestType.value1}`,
            });
            break;
          default:
            break;
        }
      } else {
        this.setState({
          tab2show: "",
        });
      }

      this.setState(
        {
          filterSelected: this.isFilterApplied(),
          tab0Show: x,
          tab1show: y,
        },
        () => this.appendFilters()
      );
    }
    if (
      prevState.getClasssboxFiltersURL !== this.state.getClasssboxFiltersURL
    ) {
      this.getClassboxesData();
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      this.setState({
        loading: false,
      });

      if (!responseJson?.errors) {
        this.setResponse(apiRequestCallId, responseJson);
      } else {
        if (
          apiRequestCallId === this.keepClassBoxApiCallId ||
          apiRequestCallId === this.extendClassBoxApiCallId
        ) {
          this.setState({
            errorMessage: true,
          });
        }
      }
      // Customizable Area End
    }
  }

  setResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getClassBoxesApiId) {
      let purchasedClassboxes = responseJson?.data.map((item: any) => {
        return (
          item?.attributes?.order_details?.type === "checkout" && {
            ...item?.attributes?.order_details,
            ...item?.attributes,
            ...item,
          }
        );
      });

      this.setState({
        classBoxes: responseJson.data,
        purchasedClassboxes: purchasedClassboxes.filter((item:any) => item),
      });
      const {location:{pathname}} = window;
      const parts = pathname.split("/");
      const lastSegment = parts[parts.length - 1];
      const path = lastSegment?.replace(/-/g, " ")
      const dataByPath = responseJson?.data.filter((data:{attributes:{title:string}}) => data?.attributes?.title === path)
      this.props.setCurrentClassbox(dataByPath[0]);

    } else if (apiRequestCallId === this.getPurchaseDetailsId) {
      this.setState({
        purchaseDetails: responseJson.data,
      });
    } else if (apiRequestCallId === this.returnClassBoxApiCallId) {
      this.props.navigation.navigate("StatusBar", {
        id: "3",
        cost: this.state.totalCost,
        tab: this.state.modalTab,
        date: this.state.newDate,
      });
    } else if (apiRequestCallId === this.requestCreditsApiCallId) {
      this.props.navigation.navigate("StatusBar", {
        id: "1",
        cost: this.state.totalCost,
        tab: this.state.modalTab,
        date: this.state.newDate,
      });
    } else if (
      apiRequestCallId === this.keepClassBoxApiCallId ||
      apiRequestCallId === this.extendClassBoxApiCallId
    ) {
      
      this.props.navigation.navigate("StatusBar", {
        id: "2",
        cost: this.state.totalCost,
        tab: this.state.modalTab,
        date: this.state.newDate,
      });
    }
  };

  getId = () => {
    if (this.props.navigation?.getParam)
      return this.props.navigation?.getParam("id");
  };

  handleCheckOut = (item: any) => {
    this.setState({
      selectedCheckoutCard: item,
      orderModalOpen: true,
    });
  };

  setClassBoxDetails = (data: any) => {
    this.props.setCurrentClassbox(data);
    const title = data?.attributes?.title?.replace(/\s+/g, "-");
    const newPath = `/Classboxes/${title}`;
    window.history.pushState(null, "", newPath);
  };

  setClassBoxDrawerCloseDetails = () => {
    this.props.setCurrentClassbox(null);
    const newPath = `/Classboxes`;
    window.history.pushState(null, "", newPath);
  };

  appendFilters = () => {
    let url = `${configJSON.getClassboxesDataApiEndPoint}?`;

    if (this.state.tab0Checkboxes.Checkout) {
      url += `&order_option=checkout`;
    }
    if (this.state.tab0Checkboxes.Purchase) {
      url += `&order_option=purchase`;
    }
    if (
      this.state.tab0Checkboxes.Checkout &&
      this.state.tab0Checkboxes.Purchase
    ) {
      url = `${configJSON.getClassboxesDataApiEndPoint}?`;
    }

    if (this.state.requestType.compare !== "") {
      url += `&credit_cost=${this.getCompareValue(
        this.state.requestType.compare
      )}`;
      if (this.state.requestType.value1 !== "") {
        url += `&value1=${this.state.requestType.value1}`;
      }
      if (this.state.requestType.value2 !== "") {
        url += `&value2=${this.state.requestType.value2}`;
      }
    }
    let y: string[] = [];
    this.state.tab1Checkboxes.STEMTechnology
      ? y.push(`"STEM Technology"`)
      : y.filter((x) => x === `"STEM Technology"`);
    this.state.tab1Checkboxes.consumableMaterials
      ? y.push(`"Consumable Materials"`)
      : y.filter((x) => x === `"Consumable Materials"`);
    this.state.tab1Checkboxes.makerspaceEquipment
      ? y.push(`"Makerspace Equipment"`)
      : y.filter((x) => x === `"Makerspace Equipment"`);
    this.state.tab1Checkboxes.reusableMaterials
      ? y.push(`"Reusable Materials"`)
      : y.filter((x) => x === `"Reusable Materials"`);

    if (y.length > 0) {
      url += `&classbox_type=[${y.join(", ")}]`;
    }
    this.setState({
      getClasssboxFiltersURL: url,
    });
    return url;
  };

  getCompareValue = (value: string) => {
    switch (value) {
      case "is":
        return "is_equal";
      case "between":
        return "in_between";
      case "morethan":
        return "more_then";
      case "lessthan":
        return "less_then";
      default:
        return "";
    }
  };

  getClassboxesData = () => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClassBoxesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.appendFilters()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };
  handleChangeTab = (value: string) => {
    this.setState({
      tab: value,
    });
    return true;
  };
  handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ sortOrder: event.target.value as string });
  };
  handleTabsButton = (value: "checkout" | "purchase" | "") => {
    if (this.state.requestType.type === value) {
      this.setState({
        requestType: {
          ...this.state.requestType,
          type: "",
          compare: "",
          value1: "",
          value2: "",
        },
        tab0Checkboxes: {
          ...this.state.tab0Checkboxes,
          [value.charAt(0).toUpperCase() + value.slice(1)]: false,
        },
      });
      return;
    }
    this.setState({
      requestType: {
        ...this.state.requestType,
        type: value,
      },
      tab0Checkboxes: {
        ...this.state.tab0Checkboxes,
        Checkout: false,
        Purchase: false,
        [value.charAt(0).toUpperCase() + value.slice(1)]: true,
      },
    });
    return true;
  };
  handleCompareChange = (
    value: "is" | "between" | "morethan" | "lessthan" | ""
  ) => {
    if (this.state.requestType.compare === value) {
      this.setState({
        requestType: {
          ...this.state.requestType,
          compare: "",
        },
      });
      return;
    }
    this.setState({
      requestType: {
        ...this.state.requestType,
        compare: value,
      },
    });
    return true;
  };
  handleChangeV1 = (e: any) => {
    (!!Number(e.target.value) || e.target.value === "") &&
      this.setState({
        requestType: {
          ...this.state.requestType,
          value1: e.target.value,
        },
      });
    return true;
  };
  handleChangeV2 = (e: any) => {
    (!!Number(e.target.value) || e.target.value === "") &&
      this.setState({
        requestType: {
          ...this.state.requestType,
          value2: e.target.value,
        },
      });
    return true;
  };
  handletab0Checkbox = (event: any) => {
    this.setState({
      tab0Checkboxes: {
        ...this.state.tab0Checkboxes,
        [event.target.name]: event.target.checked,
      },
    });
    return true;
  };
  handletab1Checkbox = (event: any) => {
    this.setState({
      tab1Checkboxes: {
        ...this.state.tab1Checkboxes,
        [event.target.name]: event.target.checked,
      },
    });
    return true;
  };
  setFilterOpen = () => {
    this.setState({
      openFilter: !this.state.openFilter,
    });
    return true;
  };
  isFilterApplied = () => {
    return (
      this.state.requestType.compare !== "" ||
      this.state.requestType.type !== "" ||
      this.state.requestType.value1 !== "" ||
      this.state.requestType.value2 !== "" ||
      this.state.tab0Checkboxes.Checkout ||
      this.state.tab0Checkboxes.Purchase ||
      this.state.tab1Checkboxes.STEMTechnology ||
      this.state.tab1Checkboxes.consumableMaterials ||
      this.state.tab1Checkboxes.makerspaceEquipment ||
      this.state.tab1Checkboxes.reusableMaterials
    );
  };
  handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    this.setState({ alignment: newAlignment });
  };
  clearFilterZero = () => {
    this.setState({
      tab0Show: [],
      tab0Checkboxes: {
        Purchase: false,
        Checkout: false,
      },
    });
    return true;
  };
  clearFilterOne = () => {
    this.setState({
      tab1show: [],
      tab1Checkboxes: {
        makerspaceEquipment: false,
        STEMTechnology: false,
        reusableMaterials: false,
        consumableMaterials: false,
      },
    });
    return true;
  };
  clearFilterTwo = () => {
    this.setState({
      tab2show: "",
      requestType: {
        type: "",
        compare: "",
        value1: "",
        value2: "",
      },
    });
    return true;
  };
  handleCloseModal = () => {
    this.setState({
      openFilter: false,
    });
    return true;
  };
  handleModalTabChange = (value: string) => {
    this.setState({
      modalTab: value,
      errorMessage: false
    });
    return true;
  };
  handleOrderModal = () => {
    this.setState({
      orderModalOpen: !this.state.orderModalOpen,
    });
    return true;
  };

  handleReturnClassBox = (id: number | string) => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.returnClassBoxApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `classbox/order_details/${id}/manage_checkout`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            status: "return_requested",
          },
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutContent
    );

    this.setState({
      orderModalOpen: false,
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleKeepClassBox = (id: number | string, cost: number) => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keepClassBoxApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `classbox/order_details/${id}/manage_checkout`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            status: "keep_requested",
            cost: cost,
            keep_request_cost:cost
          },
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutContent
    );
    this.setState({
      loading: true,
      totalCost: cost,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleExtendClassBox = (
    id: number | string,
    cost: number,
    end_date: string,
    start_date: string,
    quantity:any

  ) => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.extendClassBoxApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `classbox/order_details/${id}/manage_checkout`
    );

    let date = new Date(
      new Date(end_date).setMonth(new Date(end_date).getMonth() + 1)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            status: "extended",
            cost: cost * quantity,
            start_date: new Date(end_date),
            end_date: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
          },
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutContent
    );

    this.setState({
      loading: true,
      totalCost: cost,
      newDate: `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleButtonClick = (subject:string) => {
    this.setState({ activeSubject: subject });
  };
  requestCreditsForTraining = (
    request_type: string,
    classbox_id: number | string,
    CreditsToOwnWithCheckoutCost : any
  ) => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestCreditsApiCallId = requestMessage.messageId;
    let obj = {
      request_type: request_type,
      classbox_id: classbox_id,
      order_status_request :  "checkout",
    }
    
    if(request_type === "keep_requested"){
      Object.assign(obj,{keep_request_cost :  CreditsToOwnWithCheckoutCost})
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_trainings/teacher_requests`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(obj)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      orderModalOpen: false,
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

}
// Customizable Area End

// Customizable Area End
